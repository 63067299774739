import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// product logos
import coinPro from "../../webp/newCoinproC.webp";
import cryptoCore from "../../webp/newCryptoCC.webp";
import cryptoElite from "../../webp/newCryptoEliteC.webp";
import equityPrime from "../../webp/newEquityPC.webp";
import forexThrive from "../../webp/newForexTC.webp";
import learnLive from "../../webp/newLearnliveC.webp";
import marketPro from "../../webp/newMarketproC.webp";
import moneyPro from "../../webp/newMoneyProC.webp";
import sequilizer from "../../webp/newSequalC.webp";
import travelPro from "../../webp/newXPpub.webp";
import university from "../../webp/newUniversityC.webp";

const StyledDiv = styled.div`
	overflow-x: hidden;
	background: linear-gradient(
		180deg,
		rgba(242, 242, 242, 1) 50%,
		rgba(255, 255, 255, 1) 100%
	);
	padding: 70px 0;
	text-align: left;

	.content-container {
		display: grid;
		grid-template-columns: 40% 60%;
		align-items: center;

		.left-side {
			.divider {
				margin-left: 0;
			}

			p {
				width: 90%;
				margin: 0;
				padding-bottom: 40px;
			}
		}

		.right-side {
			

			.carouselOne {
				padding: 0px 0;
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				row-gap: 20px;
				column-gap: 15px;

				.productDivS {
					text-align: center;
					margin: auto;
				}

				.product-img {
					width: 100%;
					padding: 0 5px;
					grid-column: span 2;
				}
			}
		
		}
	}

	@media screen and ${breakpoints.device.tablet} {
		.content-container {
			grid-template-columns: 100%;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		padding: 30px 10px;
		text-align: center;

		.content-container {
			row-gap: 35px;
			.left-side {
				padding-bottom: 5px;

				.divider {
					margin: 25px auto;
				}

				p {
					padding-bottom: 0px;
					margin: auto;
				}
			}
			.right-side {

				.carouselOne {
					grid-template-columns: repeat(5, 1fr);
				}
				.carousel {
					padding: 10px 0;
				}
			}
		}
	}
`;

const ProductsSection = () => {
	return (
		<StyledDiv>
			<motion.div className="content-container">
				<div className="left-side">
					<div className="heading">Products</div>
					<div className="divider"></div>
					<p>
						<b>Access tools</b> that can
						help you reduce debt, repair and
						build your credit, manage your
						budgets, save money, improve
						your financial habits, and learn
						about the financial markets.
					</p>
					<Link to="/products">
						<button className="section-btn">
							View Products
						</button>
					</Link>
				</div>
				<div className="right-side">
					<div className="carouselOne">
						<motion.div
							initial={{
								opacity: 0,
								y: -100,
							}}
							whileInView={{
								opacity: 1,
								y: 0,
							}}
							viewport={{
								once: true,
							}}
							transition={{
								type: "tween",
								duration: 1,
							}}
							className="addSpan">
							<img
								src={university}
								className="product-img"
								alt=""
							/>
						</motion.div>
					

						<motion.div
							initial={{
								opacity: 0,
								y: -100,
							}}
							whileInView={{
								opacity: 1,
								y: 0,
							}}
							viewport={{
								once: true,
							}}
							transition={{
								type: "tween",
								duration: 1,
								delay: 0.2,
							}}
							className="addSpan">
							<img
								src={marketPro}
								className="product-img"
								alt=""
							/>
						</motion.div>
						
						<motion.div
							initial={{
								opacity: 0,
								y: -100,
							}}
							whileInView={{
								opacity: 1,
								y: 0,
							}}
							viewport={{
								once: true,
							}}
							transition={{
								type: "tween",
								duration: 1,
								delay: 0.4,
							}}
							className="addSpan">
							<img
								src={travelPro}
								className="product-img"
								alt=""
							/>
						</motion.div>
						<motion.div
							initial={{
								opacity: 0,
								y: -100,
							}}
							whileInView={{
								opacity: 1,
								y: 0,
							}}
							viewport={{
								once: true,
							}}
							transition={{
								type: "tween",
								duration: 1,
								delay: 0.6,
							}}
							className="addSpanTwo">
							<img
								src={moneyPro}
								className="product-img ChangeImg"
								alt=""
							/>
						</motion.div>
						<motion.div
							initial={{
								opacity: 0,
								y: -100,
							}}
							whileInView={{
								opacity: 1,
								y: 0,
							}}
							viewport={{
								once: true,
							}}
							transition={{
								type: "tween",
								duration: 1,
								delay: 0,
							}}
							className="addSpan">
							<img
								src={cryptoCore}
								className="product-img"
								alt=""
							/>
						</motion.div>
						<motion.div
							initial={{
								opacity: 0,
								y: -100,
							}}
							whileInView={{
								opacity: 1,
								y: 0,
							}}
							viewport={{
								once: true,
							}}
							transition={{
								type: "tween",
								duration: 1,
								delay: 0.2,
							}}
							className="addSpan">
							<img
								src={
									forexThrive
								}
								className="product-img"
								alt=""
							/>
						</motion.div>
						<motion.div
							initial={{
								opacity: 0,
								y: -100,
							}}
							whileInView={{
								opacity: 1,
								y: 0,
							}}
							viewport={{
								once: true,
							}}
							transition={{
								type: "tween",
								duration: 1,
								delay: 0.4,
							}}
							className="addSpan">
							<img
								src={learnLive}
								className="product-img"
								alt=""
							/>
						</motion.div>
						<motion.div
							initial={{
								opacity: 0,
								y: -100,
							}}
							whileInView={{
								opacity: 1,
								y: 0,
							}}
							viewport={{
								once: true,
							}}
							transition={{
								type: "tween",
								duration: 1,
								delay: 0.6,
							}}
							className="addSpan">
							<img
								src={
									equityPrime
								}
								className="product-img"
								alt=""
							/>
						</motion.div>
					</div>
				</div>
			</motion.div>
		</StyledDiv>
	);
};

export default ProductsSection;
