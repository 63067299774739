import { FaCheck, FaTimes } from "react-icons/fa";
import smartf from '../images/memberBsmartLogo.png';

const SmartBenefits = () => {
    return (
        <>
        <div style={{textAlign:'center'}}>
            <img src={smartf} className="smartflogo"/>
        </div>
        <table>
					<tbody>
						<tr className="header TravelProHeader">
							<th className="col-1">
								Core Benefits
							</th>
							<th>Retail</th>
							<th>Choice</th>
							<th>Premium</th>
							<th>Elite</th>
							
						</tr>
						<tr>
							<td className="col-1">
								One Time Membership Price
							</td>
							<td>$199.99</td>
							<td>$299.99</td>
							<td>$799.99</td>
							<td>$1699.99</td>
							
						</tr>
						<tr>
							<td className="col-1">
								Monthly Renewal
								Fee
							</td>
							<td>$59.99</td>
							<td>$149.99</td>
							<td>$224.99</td>
							<td>$224.99</td>
							
						</tr>
						<tr>
							<td className="col-1">
								SAVEpro
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
							
						</tr>
						<tr>
							<td className="col-1">
								CREDITpro
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
							
							
						</tr>
						<tr>
							<td className="col-1">
								DEBTpro
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
							
							
						</tr>
						<tr>
							<td className="col-1">
								TAXpro
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
							
							
						</tr>
						<tr>
							<td className="col-1">
								HEALTHpro
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
							
							
						</tr>
						<tr>
							<td className="col-1">
                                LEGALpro
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
							
							
						</tr>
						<tr>
							<td className="col-1">
                                BIZpro
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
							
						</tr>
						<tr>
							<td className="col-1">
								University
							</td>
							<td>
							
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								LearnLIVE
							</td>
							<td>
							
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								MONEYpro
							</td>
							<td>
								
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								MARKETpro
							</td>
							<td>
								
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								FOREXthrive{" "}
								<span className="sm-txt">
									(FX Delta, FX Essentials, and More!)
								</span>
							</td>
							<td>
								
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						
						<tr>
							<td className="col-1">
								CRYPTOcore{" "}
								<span className="sm-txt">
									(Crypto Talk,
									Crypto Surge, and More!)
								</span>
							</td>
							<td >
								
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						
						<tr>
							<td className="col-1">
								EQUITYprime{" "}
								<span className="sm-txt">
									(Trend Pro,
									Options Pro
									and
									More!)
								</span>
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>

						<tr>
							<td className="col-1">
								ALPHAtrader{" "}
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
					
						<tr>
							<td className="col-1">
								ELITE Advantage
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								CRYPTOelite
								powered by
								Endotech
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
					
						<tr>
							<td className="col-1">
							Elite Exclusive Channels <span className="sm-txt">(Crypto Flow)</span>
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								Discounts on Various Products and Services
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								Elite Advantage
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
					
						
					</tbody>
				</table>
                </>
    )
}

export default SmartBenefits;