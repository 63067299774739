import React from "react";
import styled from "styled-components";
import igeniusCaresLogo from "../../images/newblueigcares.png";
import igeniusCaresHomeImg from "../../images/togoAfrica.png";
import { breakpoints } from "../../utils/breakpoints";
import { motion } from "framer-motion";

const StyledDiv = styled.div`
	overflow-x: hidden;
	padding: 100px 0;
	background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%);

	.content-container {
		display: grid;
		grid-template-columns: 50% 50%;
		align-items: center;

		.igenius-cares-text {
			text-align: left;
			padding-left: 75px;

			.igenius-cares-logo {
				img {
					width: 50%;
				}
			}

			.divider {
				margin-left: 0;
			}

			p {
				width: 100%;
				margin: 0;
				padding-bottom: 50px;
			}
		}

		.igenius-cares-img {
			border-radius: 4px;

			img {
				width: 100%;
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		padding: 10px 10px;

		.content-container {
			grid-template-columns: 1fr;
			row-gap: 10px;

			.igenius-cares-text {
				grid-row: 1;
				padding: 0 0 0px 0;
				text-align: center;

				.divider {
					margin: 25px auto;
				}

				p {
					width: 100%;
					padding-bottom: 15px;
				}
			}

			.igenius-cares-img {
				width: 100%;
			}
		}
	}
`;

const IGeniusCaresSection = () => {
	return (
		<StyledDiv>
			{window.innerWidth > 750 ? (
				<div className="content-container">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="igenius-cares-img">
							<img
								src={
									igeniusCaresHomeImg
								}
								alt=""
							/>
						</div>
					</motion.div>
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="igenius-cares-text">
							<div className="igenius-cares-logo">
								<img
									src={
										igeniusCaresLogo
									}
									alt=""
								/>
							</div>
							<div className="divider"></div>
							<p>
							iGenius Cares is raising funds to build a primary school in Togo, Africa that will provide numerous kids with a safe place to gain an education.
							</p>
							<a
								href="https://www.gofundme.com/f/igenius-cares-build-a-school-in-togo-africa"
								target="_blank"
								rel="noreferrer">
								<button className="section-btn">
									Donate
									Now
								</button>
							</a>
						</div>
					</motion.div>
				</div>
				
			) : (
				<div className="content-container">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="igenius-cares-text">
							<div className="igenius-cares-logo">
								<img
									src={
										igeniusCaresLogo
									}
									alt=""
								/>
							</div>
							<div className="divider"></div>
							<p>
								iGenius Cares is raising funds to build a primary school in Togo, Africa that will provide numerous kids with a safe place to gain an education.
							</p>
						</div>
					</motion.div>
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="igenius-cares-img">
							<img
								src={
									igeniusCaresHomeImg
								}
								alt=""
							/>
						</div>
						<br />
						<a
							href="https://www.gofundme.com/f/iGenius-Cares"
							target="_blank"
							rel="noreferrer">
							<button className="section-btn">
								Donate Now
							</button>
						</a>
					</motion.div>
				</div>
			)}
		</StyledDiv>
	);
};

export default IGeniusCaresSection;
