import React from "react";
import styled from "styled-components";
import TopBanner from "../../Sections/TopBanner/TopBanner";
import XploreChart from "../../Sections/XploreChart";
import SmartBenefits from "../../Sections/SmartBenefits";
import newBanner from "../../images/compressedMember.jpg";
import mobileBanner from "../../images/mobileOpportPcompressed.png";
import { FaCheck, FaTimes } from "react-icons/fa";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	overflow-x: hidden;
	background: #ffffff 0% 0% no-repeat padding-box;
	.spanTravel {
		padding: 15px;
		text-align: left;
		font-size: 18px;
		margin-left: 20px;
		width: 90%;
		margin: auto;
		line-height: 2;
	}

	.smartflogo{
	width: 50%;
	}

	.content-container {
		padding: 100px 0;
		font: normal normal normal 1.5vw/3vw Nexa-light;

		.heading {
			padding-bottom: 50px;
		}

		table {
			width: 90%;
			margin: auto;
			border-collapse: collapse;
			margin-top: 50px;
			background: #fefefe 0% 0% no-repeat padding-box;
			box-shadow: 0px 3px 16px #e9e9e980;
			border-radius: 4px;

			.col-1 {
				text-align: left;
				width: 50%;
			}

			.x {
				opacity: 0.25;
			}

			th {
				padding: 25px;
			}

			td {
				font: normal normal normal 1.5vw/3vw Nexa-light;
				padding: 10px 25px;

				.sm-txt {
					font-size: 1vw;
				}
			}

			tr {
				border-bottom: 1px solid #ddd;

				&:nth-child(odd) {
					background-color: #f9f9f9;
				}

				&:hover {
					background-color: #ebf2f7;
				}
			}
		}

		.TravelProHeader {
			background-color: lightgrey !important;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.spanTravel {
			font-size: 2.5vw;
		}
		.content-container {
			font-size: 3vw;
			padding: 50px 0;

			table {
				td,
				th {
					padding: 10px;
				}

				td {
					font-size: 2.5vw;

					.sm-txt {
						font-size: 2vw;
					}
				}
			}
		}
	}
`;

const MemberBenefits = () => {
	return (
		<StyledDiv>
			<TopBanner
				bg={
					window.innerWidth < 750
						? mobileBanner
						: newBanner
				}
				txt={
					<>
						<b>iGenius helps people </b>
						solve their problems in new and
						brilliant ways.
					</>
				}
			/>
			<div className="content-container">
				<div data-aos="fade-down">
					<div className="heading">Benefits</div>
					<p>
						iGenius has a membership for
						anyone interested in improving
						their relationship with money.
					</p>
				</div>
				<table>
					<tbody>
						<tr className="header TravelProHeader">
							<th className="col-1">
								Core Benefits
							</th>
							<th>Choice</th>
							<th>Select</th>
							<th>Premium</th>
							<th className="elite">
								Elite
							</th>
						</tr>
						<tr>
							<td className="col-1">
								One-Time Fee
							</td>
							<td>$99.99</td>
							<td>$299.99</td>
							<td>$599.99</td>
							<td className="elite">
								$1,499.99
							</td>
						</tr>
						<tr>
							<td className="col-1">
								Monthly Renewal
								Fee
							</td>
							<td>$99.99</td>
							<td>$174.99</td>
							<td>$174.99</td>
							<td className="elite">
								$174.99
							</td>
						</tr>
						<tr>
							<td className="col-1">
								University
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								LearnLIVE
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								MONEYpro
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								MARKETpro
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								FOREXthrive{" "}
								<span className="sm-txt">
									(FX Delta, FX Essentials, and More!)
								</span>
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						
						<tr>
							<td className="col-1">
								CRYPTOcore{" "}
								<span className="sm-txt">
									(Crypto Talk,
									Crypto Surge, and More!)
								</span>
							</td>
							<td >
								
							</td>
							<td>
								<FaCheck />
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						
						<tr>
							<td className="col-1">
								EQUITYprime{" "}
								<span className="sm-txt">
									(Trend Pro,
									Options Pro
									and
									More!)
								</span>
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td>
								<FaCheck />
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						
						<tr>
							<td className="col-1">
								ALPHAtrader{" "}
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
					
						<tr>
							<td className="col-1">
								ELITE Advantage
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								CRYPTOelite
								powered by
								Endotech
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						
						<tr>
							<td className="col-1">
								Elite Exclusive Channels <span className="sm-txt">(Crypto Flow)</span>
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								Discounts on Various Products and Services
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						<tr>
							<td className="col-1">
								Elite Advantage
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="x">
								
							</td>
							<td className="elite">
								<FaCheck />
							</td>
						</tr>
						
					</tbody>
				</table>
				<br />
				<div style={{textAlign: 'center'}}>
					<p>CRYPTOelite is not available to residents of Canada or Belgium.</p>
					
				</div>
				<p className="spanTravel">
					
				</p>
				<br />
				<br />
				<XploreChart />
				<br />
				<br />
				<SmartBenefits />
			</div>
		</StyledDiv>
	);
};

export default MemberBenefits;
