import React, { useState, useEffect } from "react";
import ProductCarousel from "./ProductCarousel";
import ProductSection from "./ProductSection";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import topText1 from "../../webp/Text_Background_for_all_pages.webp";
import topText2 from "../../webp/Text_Background_for_all_pages_2.webp";
import topText3 from "../../webp/Text_Background_for_all_pages_3.webp";
import Modal from "../../Sections/Modal/Modal";

// carousel phone images
import cryptoCorePhoneCarousel from "../../webp/crypto core.webp";
import equityPrimePhoneCarousel from "../../webp/equity prime.webp";
import forexThrivePhoneCarousel from "../../webp/forex thrive.webp";
import learnLivePhoneCarousel from "../../webp/learn live.webp";
import marketProPhoneCarousel from "../../webp/market pro.webp";
import moneyProPhoneCarousel from "../../webp/money pro.webp";
import smartFinancePhoneCarousel from "../../webp/smart finance.webp";
import travelProPhoneCarousel from "../../webp/topPhoneXplorepro.webp";
import universityPhoneCarousel from "../../webp/university.webp";

// phone images
import cryptoCorePhone from "../../webp/cryptoCore-phone.webp";
import equityPrimePhone from "../../webp/equityPrime-phone.webp";
import forexThrivePhone from "../../webp/forexThrive-phone.webp";
import learnLivePhone from "../../webp/learnLive-phone.webp";
import marketProPhone from "../../webp/marketPro-phone.webp";
import moneyProPhone from "../../webp/moneyPro-phone.webp";
import smartFinancePhone from "../../webp/smartFinance-phone.webp";
import travelProPhone from "../../webp/publicsite-phone-xpro.webp";
import universityPhone from "../../webp/university-phone.webp";

// thumbnail images
import cryptoCoreImg from "../../webp/cryptoCore-img.webp";
import equityPrimeImg from "../../webp/equityPrime-img.webp";
import forexThriveImg from "../../webp/forexThrive-img.webp";
import learnLiveImg from "../../webp/learnLive-img.webp";
import marketProImg from "../../webp/marketPro-img.webp";
import moneyProImg from "../../webp/moneyPro-img.webp";
import smartFinanceImg from "../../webp/smartFinance-img.webp";
import travelProImg from "../../webp/publicsite-tbn-xpro.webp";
import universityImg from "../../webp/university-img.webp";

const StyledDiv = styled.div`
	overflow-x: hidden;
	background: #ffffff 0% 0% no-repeat padding-box;

	.product-banner {
		width: 100%;
		margin-top: 25px;

		.heading {
			padding: 50px 0;
			font-size: 2vw;
			width: 100%;
		}
	}

	.topText {
		width: 100%;
		margin-top: -4px;
		position: relative;
		height: 25vh;

		.sub-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			width: 70%;
		}
	}

	.content-container {
		padding-bottom: 100px;

		.left {
			grid-template-areas:
				"phone txt"
				"phone img";
		}

		.right {
			grid-template-areas:
				"txt phone"
				"img phone";
		}

		.modal-container {
			display: none;
		}
	}

	.banner-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: transparent
			linear-gradient(
				180deg,
				rgba(79, 80, 82, 0) 20%,
				rgba(255, 255, 255, 1) 100%
			)
			0% 0% no-repeat padding-box;
	}

	@media screen and ${breakpoints.device.mobileL} {
		.product-banner {
			.heading {
				font-size: 4vw;
				line-height: 7vw;
				width: 75%;
			}
		}

		.topText {
			.sub-text {
				font-size: 4vw;
				padding: 25px 0;
			}
		}

		.content-container {
			.left,
			.right {
				grid-template-areas:
					"txt txt txt"
					"phone phone phone"
					"img img img";
			}
		}
	}
`;

const Products = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [vidType, setVidType] = useState(null);
	const [vidCode, setVidCode] = useState(null);

	useEffect(() => {
		var modal = document.getElementById("modal");
		var video = document.getElementById("vimeo-iframe");

		if (isModalOpen) {
			modal.style.display = "block";
		} else {
			modal.style.display = "none";
			var temp = video.getAttribute("src")
			video.setAttribute("src", temp);
		}
	}, [isModalOpen]);

	return (
		<StyledDiv>
			<div className="product-banner">
				<ProductCarousel
					images={carouselPhoneImages}
					slidesToShow={5}
				/>

				<div className="topText">
					<p className="sub-text">
						iGenius offers a variety of{" "}
						<b>education and tools</b> for
						the financial markets.
						<br />
					</p>
					<img
						src={
							window.screen.width >
								1000
								? topText1
								: window.screen
									.width >
									500
									? topText2
									: topText3
						}
						alt="topText"
						className="topText"
					/>
				</div>
			</div>
			<div className="content-container">
				<div className="products_container">
					{sectionData.map((data, idx) => (
						<ProductSection
							key={idx}
							data={data}
							idx={idx}
							isModalOpen={
								isModalOpen
							}
							setIsModalOpen={
								setIsModalOpen
							}
							setVidType={setVidType}
							setVidCode={setVidCode}
						/>
					))}
				</div>
				<div id="modal" className="modal-container">
					<Modal
						vidType={vidType}
						vidCode={vidCode}
						setIsModalOpen={setIsModalOpen}
					/>
				</div>
			</div>
		</StyledDiv>
	);
};

// link # = order in product section
const carouselPhoneImages = [
	{ image: cryptoCorePhoneCarousel, link: "#8" },
	{ image: equityPrimePhoneCarousel, link: "#7" },
	{ image: forexThrivePhoneCarousel, link: "#6" },
	{ image: learnLivePhoneCarousel, link: "#2" },
	{ image: marketProPhoneCarousel, link: "#3" },
	{ image: travelProPhoneCarousel, link: "#4" },
	{ image: moneyProPhoneCarousel, link: "#5" },
	{ image: smartFinancePhoneCarousel, link: "#0" },	
	{ image: universityPhoneCarousel, link: "#1" },
];

const sectionData = [
	{
		txt: (
			<>
				<b>smartFINANCE</b> provides financial education
				and tools that can help you improve your
				financial habits.
			</>
		),
		phone: smartFinancePhone,
		phoneVidType: "youtube",
		phoneVidCode: "https://www.instagram.com/reel/Cikllc8JC9N/",
		thumbnail: smartFinanceImg,
		thumbVidType: "youtube",
		thumbVidCode: "qPilNQmj0U4",
		phoneSide: "left",
	},
	{
		txt: (
			<>
				<b>University</b> provides education and
				training for the financial markets.
			</>
		),
		phone: universityPhone,
		phoneVidType: "youtube",
		phoneVidCode: "https://www.instagram.com/reel/Cis_A62JdTE/",
		thumbnail: universityImg,
		thumbVidType: "vimeo",
		thumbVidCode: "699478371",
		phoneSide: "right",
	},
	{
		txt: (
			<>
				<b>learnLIVE</b> provides live and recorded
				market education sessions hosted by our market
				educators.
			</>
		),
		phone: learnLivePhone,
		phoneVidType: "youtube",
		phoneVidCode: "https://www.instagram.com/reel/Cj29Vh1OPvp/",
		thumbnail: learnLiveImg,
		thumbVidType: "vimeo",
		thumbVidCode: "699477585",
		phoneSide: "left",
	},
	{
		txt: (
			<>
				<b>MARKETpro</b> delivers a robust library of
				market educational videos, eBooks, research, and
				calendars.
			</>
		),
		phone: marketProPhone,
		phoneVidType: "youtube",
		phoneVidCode: "https://www.instagram.com/reel/Ckqx_UZJzdC/",
		thumbnail: marketProImg,
		thumbVidType: "vimeo",
		thumbVidCode: "699475732",
		phoneSide: "right",
	},
	{
		txt: (
			<>
				<b>XPLOREpro</b> offers you incredible rates on travel and free vacations so you can explore the world for less! 
			</>
		),
		phone: travelProPhone,
		phoneVidType: "youtube",
		phoneVidCode: "https://www.instagram.com/p/C3I2c-aPZjv/",
		thumbnail: travelProImg,
		thumbVidType: "vimeo",
		thumbVidCode: "911676146",
		phoneSide: "right",
	},
	{
		txt: (
			<>
				<b>MONEYpro</b> helps you reduce debt, manage
				budgets, and improve your financial habits.
			</>
		),
		phone: moneyProPhone,
		phoneVidType: "youtube",
		phoneVidCode: "https://www.instagram.com/reel/CjlT8PBJm4u/",
		thumbnail: moneyProImg,
		thumbVidType: "vimeo",
		thumbVidCode: "539185295",
		phoneSide: "left",
	},
	{
		txt: (
			<>
				<b>FOREXthrive</b> provides access to unique
				research, education, and strategies for the
				forex market.
			</>
		),
		phone: forexThrivePhone,
		phoneVidType: "youtube",
		phoneVidCode: "https://www.instagram.com/reel/CjQ-lbiM6NM/",
		thumbnail: forexThriveImg,
		thumbVidType: "vimeo",
		thumbVidCode: "591261714",
		phoneSide: "right",
	},
	{
		txt: (
			<>
				<b>EQUITYprime</b> offers comprehensive
				education and research for the equity markets.
			</>
		),
		phone: equityPrimePhone,
		phoneVidType: "youtube",
		phoneVidCode: "https://www.instagram.com/reel/CjGtO56N-wH/",
		thumbnail: equityPrimeImg,
		thumbVidType: "vimeo",
		thumbVidCode: "591261732",
		phoneSide: "left",
	},
	{
		txt: (
			<>
				<b>CRYPTOcore</b> simplifies the complex world
				of cryptocurrencies.
			</>
		),
		phone: cryptoCorePhone,
		phoneVidType: "youtube",
		phoneVidCode: "https://www.instagram.com/reel/Ci-5SCUOZf5/ ",
		thumbnail: cryptoCoreImg,
		thumbVidType: "vimeo",
		thumbVidCode: "591261810",
		phoneSide: "right",
	}
];

export default Products;
