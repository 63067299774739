import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BottomBanner from "../../Sections/BottomBanner/BottomBanner";
import bg from "../../images/igCaresBottom.png";
import { breakpoints } from "../../utils/breakpoints";
import img1 from "../../images/iGeniusImg-1.png";
import img2 from "../../images/iGeniusImg-2.png";
import img3 from "../../images/iGeniusImg-3.png";
import img4 from "../../images/iGenius-KAH.png";
import cancer from "../../images/FightingCaner.png";
import surgery from "../../images/CataractSurgeries.png";
import preschool from "../../images/PreschoolTogo.png";
import togo from "../../images/togoAfrica.png";
import hygienekits from "../../images/hygieneKits.png";
import dis from "../../images/disabilitesIG.webp";
import { motion } from "framer-motion";
import Modal from "../../Sections/Modal/Modal";

// banner video
import bannerVid from "../../videos/iGenius Cares Website Banner.mp4";
import TopBannerVideo from "../../Sections/TopBanner/TopBannerVideo";



const StyledDiv = styled.div`
	overflow-x: hidden;
	background: #ffffff 0% 0% no-repeat padding-box;

	.removeunder{
		color: #44b2dd;
		text-decoration: none;
	}

	.videoCares {
		width: 100%;
	}

	.content-container {
		padding: 100px 0;
		display: grid;
		grid-template-columns: 1fr;
		row-gap: 230px;
		align-items: center;
		text-align: left;

		.horizontal-section {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.image-section {
				width: 50vw;
				border-radius: 4px;

				img {
					width: 100%;
				}
			}

			.txt-section {
				width: 40%;

				p {
					font-style: normal;
					width: 80%;
					padding: 25px 0;
					margin: 0;
				}

				.section-btn {
					margin-bottom: 25px;
				}
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			padding: 20px 0;
			row-gap: 40px;

			.horizontal-section {
				flex-direction: column;

				.image-section {
					width: 90vw;
				}

				.txt-section {
					width: 90%;
					text-align: center;

					p {
						margin: auto;
					}
				}
			}

			.mid-section {
				flex-direction: column-reverse;
			}

			.heading {
				line-height: 2;
				display: none;
			}
		}
	}
`;

const IGeniusCares = () => {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [vidCode, setVidCode] = useState("");

	useEffect(() => {
		var modal = document.getElementById("modal");
		var video = document.getElementById("vimeo-iframe");

		if (isModalOpen) {
			modal.style.display = "block";
		} else {
			modal.style.display = "none";
			var temp = video.getAttribute("src")
			video.setAttribute("src", temp);
		}
	}, [isModalOpen]);


	return (
		<StyledDiv>
			<TopBannerVideo
				video={bannerVid}
				txt={
					<>
						<b>Our goal</b> is to provide
						services and opportunities
						essential to helping those in
						distress secure lasting and
						balanced success.
					</>
				}
			/>
			<div className="content-container">
			<div className="horizontal-section">
						<div className="image-section">
							<motion.div
								initial={{ opacity: 0, x: -200 }}
								whileInView={{
									opacity: 1,
									x: 0,
								}}
								viewport={{ once: true }}
								transition={{
									type: "tween",
									duration: .8,
								}}>
								<img src={togo} alt="" />
							</motion.div>
						</div>
						<div className="txt-section">
						<motion.div
							initial={{ opacity: 0, x: 200 }}
							whileInView={{
								opacity: 1,
								x: 0,
							}}
							viewport={{ once: true }}
							transition={{
								type: "tween",
								duration: .8,
							}}>
							<div className="heading">
							Raising Funds to Build a School in Africa
							</div>
							<p>
							iGenius Cares is raising funds to build a primary school in Togo, Africa that will provide numerous kids with a safe place to gain an education. Please join us in making a lasting impact. Donate today and be a part of something truly life-changing!
							</p>
							<a href="https://www.gofundme.com/f/igenius-cares-build-a-school-in-togo-africa"
								target="_blank">
								<button 
							 	
								rel="noreferrer"
								className="section-btn">
								Learn More / Donate Now
							</button>
							</a>
							
							</motion.div>
						</div>
						
					</div>
				<div className="horizontal-section mid-section">
						<div className="txt-section">
						<motion.div
							initial={{ opacity: 0, x: -200 }}
							whileInView={{
								opacity: 1,
								x: 0,
							}}
							viewport={{ once: true }}
							transition={{
								type: "tween",
								duration: .8,
							}}>
							<div className="heading">
							Preschool In Togo 
							</div>
							<p>
							iGenius Cares, along with the DACH community, raised enough funds to build a preschool in Togo, Africa. With the assistance of the FLY & HELP Foundation and Ossara e.V., the new school is helping 80+ children start gaining an education. To learn more about this initiative, click <a class="removeunder" rel="noreferrer" href="https://www.fly-and-help.de/en/projects/d-lassa-lao-478537697" target="_blank">here</a>.
							</p>
							<button 
							 	onClick={() => {
									setIsModalOpen(
										!isModalOpen
									);
									setVidCode(
										"NDbTuTEES88"
									);
								}} 
								className="section-btn">
								Grand Opening Video
							</button>
							
							</motion.div>
						</div>
						<div className="image-section">
						<motion.div
							initial={{ opacity: 0, x: 200 }}
							whileInView={{
								opacity: 1,
								x: 0,
							}}
							viewport={{ once: true }}
							transition={{
								type: "tween",
								duration: .8,
							}}>
							<img src={preschool} alt="" />
							</motion.div>
						</div>
					</div>
			
			<div className="horizontal-section">
						<div className="image-section">
						<motion.div
							initial={{ opacity: 0, x: -200 }}
							whileInView={{
								opacity: 1,
								x: 0,
							}}
							viewport={{ once: true }}
							transition={{
								type: "tween",
								duration: .8,
							}}>
							<img src={cancer} alt="" />
							</motion.div>
						</div>
						<div className="txt-section">
						<motion.div
							initial={{ opacity: 0, x: 200 }}
							whileInView={{
								opacity: 1,
								x: 0,
							}}
							viewport={{ once: true }}
							transition={{
								type: "tween",
								duration: .8,
							}}>
								<div className="heading">
								Fight Against Breast Cancer
								</div>
								<p>
								iGenius Cares and over 500 women donated towards the fight against breast cancer. Donations were given to Brustkrebs Deutschland e.V. to help support breast cancer patients. 
								</p>
								
							</motion.div>
						</div>
					</div>
					<div className="horizontal-section mid-section">
					<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<div className="heading">
						276 Cataract Surgeries
						</div>
						<p>
						iGenius Cares raised funds to help 276 adults receive cataract surgery. Donations were given to several clinics across Africa to help adults improve their eyesight.
						</p>
						</motion.div>
					</div>
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={surgery} alt="" />
						</motion.div>
					</div>
				</div>
				<div className="horizontal-section">
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={img4} alt="" />
						</motion.div>
					</div>
					<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
							<div className="heading">
								Fighting Hunger
							</div>
							<p>
								iGenius Cares teamed up with
								Kids Against
								Hunger to
								provide fully
								nutritious food
								to impoverished
								children and
								families around
								the world...and
								around the
								corner.
							</p>
							
						</motion.div>
					</div>
				</div>
				<div className="horizontal-section">
				<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
							<div className="heading">
							Supports Those With Disabilities
							</div>
							<p>
							iGenius Cares raised funds and awareness to help those with disabilities fight loneliness by building new friendships and spending time together doing fun, interactive activities. 
							</p>
							
						</motion.div>
					</div>
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={dis} alt="" />
						</motion.div>
					</div>
					
				</div>
				<div className="horizontal-section mid-section">
					
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={img1} alt="" />
						</motion.div>
					</div>
					<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<div className="heading">
						Raised Funds For O.U.R.
						</div>
						<p>
						iGenius Cared helped raise funds for Operation Underground Railroad. O.U.R. rescues children who have been sex trafficked and shines a light on the horrific issue. 
						</p>
						</motion.div>
					</div>
				</div>
				<div className="horizontal-section">
					
					<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<div className="heading">
							150 Sack Lunches Donated
						</div>
						<p>
							iGenius Cares put
							together sack lunches
							for children at the Road
							Home. The Road Home is a
							private non-profit
							social services agency
							that assists individuals
							and families
							experiencing
							homelessness.
						</p>
						</motion.div>
					</div>
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={img2} alt="" />
						</motion.div>
					</div>
				</div>
				<div className="horizontal-section mid-section">
					
					<div className="image-section">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<img src={hygienekits} alt="" />
						</motion.div>
					</div>
					<div className="txt-section">
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: .8,
						}}>
						<div className="heading">
						1,000 Hygiene Kits Assembled
						</div>
						<p>
							iGenius Cares purchased
							supplies from Clean the
							World and assembled over
							1,000 humanitarian kits.
							The kits were donated to
							shelters for women and
							children who are victims
							of abuse.
						</p>
						</motion.div>
					</div>
				</div>
				<div id="modal" className="modal-container" style={{ display: 'none' }}>
					<Modal
						vidType="youtube"
						vidCode={vidCode}
						setIsModalOpen={setIsModalOpen}
					/>
				</div>
			</div>
			<BottomBanner
				bg={bg}
				txt={
					"The iGenius Cares mission is to make a measurable impact on those in need and provide them opportunities to plan for a self-sustainable future."
				}
				btn={
					<>
						<a
							href="https://www.gofundme.com/f/igenius-cares-build-a-school-in-togo-africa"
							target="_blank"
							rel="noreferrer">
							<button className="section-btn">
								Donate Now
							</button>
						</a>
					</>
				}
			/>
		</StyledDiv>
	);
};

export default IGeniusCares;
