import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import { Link } from "react-router-dom";

// images
import logo from "../../images/copyrightIGlogo.png";
import creditCards from "../../images/credit-cards.png";
import bfh from "../../images/business-for-home.png";
import bbb from "../../images/bbb.png";
import fb from "../../images/fb-icon.png";
import twitter from "../../images/twitter-icon.png";
import insta from "../../images/instagram-icon.png";
import yt from "../../images/yt-icon.png";
import shopify from "../../images/shopify-icon.png";
import shopifyWhite from "../../images/WhiteShopify.svg";
import instaWhite from "../../images/WhiteInsta.svg";
import ytWhite from "../../images/WhiteGroup4.svg";
import tweetWhite from "../../images/WhiteIcon.svg";
import fbWhite from "../../images/WhiteIcon-1.svg";

const StyledFooter = styled.footer`
	color: white;
	width: 100%;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(79, 80, 82, 0.25) 10%,
		rgba(79, 80, 82, 0.5) 25%,
		rgba(79, 80, 82, 1) 100%
	);

	.disclaimer{
		width: 80%;
		margin: auto;
		color: #414142
	}

	.content-container {
		padding-top: 50px;

		.top-content {
			display: flex;
			justify-content: space-between;

			img {
				width: 25vw;
				height: auto;
			}

			.left-side {
				text-align: left;

				.certifcation img {
					width: 10vw;
					padding: 50px 25px;
				}

				.icons {
					display: flex;
					align-items: center;
				}

				.icons img {
					width: 2vw;
					padding: 50px 25px;
				}
			}

			.right-side {
				display: flex;
				justify-content: flex-start;
				text-align: left;

				.link-col {
					padding: 50px;
				}

				.heading {
					font: normal normal bold 1.5vw/47px
						Nexa-bold;
					margin-bottom: 30px;
				}

				.txt-links {
					p {
						font: normal normal italic 1vw/25px
							Nexa-light;
						padding: 20px 0;
						width: auto;
						color: white;
						text-decoration: none;
						
					}

					a {
						text-decoration: none;
					}
				}
			}
		}

		.bottom-content {
			padding: 10px;
			text-align: center;
			background-color: #;

			display: flex;
			align-items: center;
			justify-content: space-between;

			.credit-img {
				width: 10%;
			}

			p {
				font: normal normal bold 1vw/26px Nexa-bold;
				letter-spacing: 0.72px;
				width: auto;
				margin: 0;
			}
		}
	}

	@media screen and ${breakpoints.device.laptop} {
		.top-content .right-side .link-col {
			padding: 30px;
		}
	}

	@media screen and ${breakpoints.device.tablet} {

		.disclaimer{
		margin-top: 25px;
		width: 95%;
		margin-right: auto;
		margin-left: auto;
		color: #414142
	}
		.content-container {
			padding-top: 20px;
			.top-content {
				flex-direction: column;
				align-items: center;

				.left-side {
					display: grid;
					grid-template-row: 1fr 1fr 1fr;
					align-items: center;
					text-align: center;

					.certifcation img {
						width: 20vw;
						padding: 25px 15px;
					}

					.icons {
						margin: auto;
						img {
							padding: 25px 15px;
							width: 5vw;
						}
					}
				}

				.right-side {
					text-align: left;
					font-style: normal;
					.heading {
						font-size: 3.75vw;
						margin: 5px;
					}

					.txt-links {
						p {
							font-size: 2.75vw;
							padding: 5px;
							font-style: normal;
						}
					}

					.link-col {
						padding: 10px;
					}
				}
			}

			.igeniusLogo {
				width: 35vw !important;
			}

			.iconsBottom {
				width: 40% !important;
			}

			.bottom-content {
				p {
					font-size: 2.5vw;
				}

				.credit-img {
					width: 20%;
				}
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		..content-container {
			.top-content {
				.left-side {
					.icons {
						img {
							width: 10vw;
							padding: 10px;
						}
					}
				}

				.right-side {
					flex-direction: column;
					text-align: center;

					.link-col {
						padding: 10px;

						.heading {
							font-size: 3.55vw;
							margin: 0;
						}

						.txt-links {
							p {
								font-size: 3.25vw;
								padding: 5px;
							}
						}
					}
				}
			}
		}
	}
`;

const Footer = () => {

	var year = new Date();
    year = year.getFullYear();

	
	return (
		<StyledFooter>
			<div className="disclaimer">
				iGenius is a wholly-owned subsidiary of Investview (OTCQB: INVU) and provides bona fide financial education, research, and analysis through disinterested and impersonal commentary rather than personalized investment advice. iGenius is not a broker, investment advisor, or fiduciary, and nothing on this website should be construed as investment advice. Users are solely responsible for their financial decisions and should conduct their own research or consult a qualified financial professional before making any investments. For full disclosures, policies, and terms, please visit igeniusglobal.com/policies.
			</div>
			<br />
			<br />
			<div className="content-container">
				<div className="top-content">
					<div className="left-side">
						<div className="logo">
							<img
								className="igeniusLogo"
								src={logo}
								alt=""
							/>
						</div>
						<div className="certifcation">
							<a
								href="https://www.businessforhome.org/companies/igenius/"
								target="_blank"
								rel="noreferrer">
								<img
									className="igeniusLogo"
									src={
										bfh
									}
									alt=""
								/>
							</a>
							<a
								href="https://www.bbb.org/us/ut/fruit-heights/profile/educational-research/igenius-1166-22364634"
								target="_blank"
								rel="noreferrer">
								<img
									className="igeniusLogo"
									src={
										bbb
									}
									alt=""
								/>
							</a>
						</div>
						<div className="icons">
							<a
								href="https://www.facebook.com/iGeniusFB"
								target="_blank"
								rel="noreferrer">
								<img
									className="iconsBottom"
									src={
										window.innerWidth >
										650
											? fb
											: fbWhite
									}
									alt=""
								/>
							</a>
							<a
								href="https://twitter.com/iGeniusGlobal"
								target="_blank"
								rel="noreferrer">
								<img
									className="iconsBottom"
									src={
										window.innerWidth >
										650
											? twitter
											: tweetWhite
									}
									alt=""
								/>
							</a>
							<a
								href="https://www.instagram.com/igeniusglobal/"
								target="_blank"
								rel="noreferrer">
								<img
									className="iconsBottom"
									src={
										window.innerWidth >
										650
											? insta
											: instaWhite
									}
									alt=""
								/>
							</a>
							<a
								href="https://www.youtube.com/channel/UC7ExwEwVh8D6TvqpY3zgKSg"
								target="_blank"
								rel="noreferrer">
								<img
									className="iconsBottom"
									src={
										window.innerWidth >
										650
											? yt
											: ytWhite
									}
									alt=""
								/>
							</a>
							<a
								href="https://igeniusglobal.myshopify.com/"
								target="_blank"
								rel="noreferrer">
								<img
									className="iconsBottom"
									src={
										window.innerWidth >
										650
											? shopify
											: shopifyWhite
									}
									alt=""
								/>
							</a>
						</div>
					</div>
					<div className="right-side">
						<div className="link-col">
							<div className="heading">
								Policies
							</div>
							<div className="txt-links">
								<Link to="/policies/purchaseterms#policy-content">
									<p>
										Purchase
										Terms
									</p>
								</Link>
								<Link to="/policies/privacypolicy#policy-content">
									<p>
										Privacy
										Policy
									</p>
								</Link>
								<Link to="/policies/distributoragreement#policy-content">
									<p>
										Distributor
										Agreement
									</p>
								</Link>
								<Link to="/policies/compliance#policy-content">
									<p>
										Compliance
										&
										Regulatory
									</p>
								</Link>
								<a
									href="https://igeniusglobal.io/documents/policies/iGenius-Policies-Procedures.pdf"
									target="_blank"
									rel="noreferrer">
									<p>
										Policies
										&
										Procedures
									</p>
								</a>
							</div>
						</div>
						<div className="link-col">
							<div className="heading">
								Contact Us
							</div>
							<div className="txt-links">
								<Link to="/contactus">
									<p>
										Email
									</p>
								</Link>
								<Link to="/contactus">
									<p>
										Phone
									</p>
								</Link>
								<Link to="/contactus">
									<p>
										Headquarters
									</p>
								</Link>
							</div>
						</div>
						<div className="link-col">
							<div className="heading">
								Who We Are
							</div>
							<div className="txt-links">
								<Link to="/leadership">
									<p>
										Leadership
									</p>
								</Link>
								<a
									href="https://investview.com/"
									target="_blank"
									rel="noreferrer">
									<p>
										Investview
									</p>
								</a>
								<Link to="/igeniuscares">
									<p>
										iGenius
										Cares
									</p>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom-content">
					<p>
						Copyright &copy; {year} iGenius LLC.
						All rights reserved.
					</p>
					<img
						src={creditCards}
						className="credit-img"
						alt=""
					/>
				</div>
			</div>
		</StyledFooter>
	);
};

export default Footer;
